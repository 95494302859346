// import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          <h1>Page supprimée par l'administrateur réseau.</h1>
        </p>
        <p>
         <h5>Effectuer le renouvellement de vôtre site web !!</h5>
        </p>
      </header>
    </div>
  );
}

export default App;
